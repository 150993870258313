<template>
  <div class="datalist">
    <div class="datalist-title">
      <div class="datalist-title-info">
        {{ title }}
      </div>
      <div class="datalist-title-action">
        <div class="action-left">
          <el-button
            v-if="!isMid && getAuthorization('dataList-create')"
            v-auto-unfocus
            type="primary"
            @click="gotoCreate"
            class="data-list-createBtn"
          >
            新建数据
          </el-button>
          <el-button
            v-if="!isMid && getAuthorization('dataList-edit')"
            v-auto-unfocus
            :disabled="!showEditBtn"
            :class="{ 'data-list-exportBtn': showEditBtn, 'data-list-eb': !showEditBtn }"
            @click="editDrawerShow = true"
          >
            编辑
          </el-button>
          <el-button
            v-if="!isMid && getAuthorization('dataList-import')"
            v-auto-unfocus
            class="data-list-importBtn"
            @click="hanldeImport"
          >
            导入
          </el-button>
          <el-button v-if="!isMid" v-auto-unfocus class="data-list-exportBtn" @click="handleExport">
            导出
          </el-button>
        </div>

        <div class="action-right">
          <el-input
            v-if="showSearchInput"
            :class="'datalist-title-input'"
            placeholder="请输入搜索关键字"
            v-model="searchText"
          >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchKeyword"></i>
          </el-input>
          <el-button icon="el-icon-refresh-right" @click="init"></el-button>
          <el-button v-if="!isMid" icon="el-icon-setting" @click="handleSetField"></el-button>
        </div>
      </div>
      <div v-if="filterList.length > 0" class="datalist-title-filter">
        <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
          {{ getLabel(item.name) }}: {{ item.label }}
          <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
        </div>
        <el-button
          v-if="filterList.length"
          class="datalist-title-button"
          type="text"
          @click="deleteAllFilter"
        >
          重置
        </el-button>
      </div>
    </div>

    <div class="datalist-table" v-show="!requestData">
      <eyao-table
        ref="table"
        :key="`${$route.query.type || ''}`"
        :table-data="tableData"
        :query-type="`${$route.query.type}`"
        :total-num="dataCount"
        :select-page-size="10"
        @selection-change="handleSelectionChange"
        @filter-change="filterChange"
        @header-dragend="fixColWidth"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <!-- 复选框列 -->
        <el-table-column v-if="!isMid" type="selection" :reserve-selection="true" />

        <!-- 下拉搜索select暂时使用filters属性实现 等各个属性的下拉选项列表可以获得之后统一由eyao-table-search-header处理 -->
        <el-table-column
          v-for="column in config"
          :key="`${$route.query.type}-${column.property}`"
          :prop="column.property"
          :column-key="column.property"
          :width="column.width"
          :min-width="column['min-width']"
          :label="column.title"
          :sortable="column.sort"
          :fixed="column.fixed"
        >
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              v-if="
                column.search === 'input' ||
                  column.search === 'cascader' ||
                  column.search === 'select' ||
                  column.search === 'autocomplete'
              "
              :form-type="column.search"
              :property="column.property"
              :title="column.title"
              :scope="scope"
              :data="cascaderData[column.property]"
              :options="valueOpts[column.property]"
              :fuzzy-search="apiData"
              @search="search"
            ></eyao-table-search-header>
            <template v-else>{{ column.title }}</template>
          </template>
          <template slot-scope="scope">
            <eyao-table-edit-cell
              v-if="column.edit"
              :form-type="column.search"
              :property="column.property"
              :title="column.title"
              :scope="scope"
              :options="valueOpts[column.property]"
              :data="cascaderData[column.property]"
              @edit="edit"
            ></eyao-table-edit-cell>
            <!-- id字段标蓝 点击后跳转 -->
            <template v-else-if="column.property === 'id'">
              <div
                @click="gotoDetail(scope.row.id, scope.row.name)"
                :style="{ color: '#0069FA', cursor: 'pointer' }"
              >
                {{ scope.row[column.property] }}
              </div>
            </template>

            <!-- name字段hover展示hco_code与hcp_code -->
            <template v-else-if="column.property === 'name'">
              <el-popover
                v-if="!isMid"
                placement="top-start"
                width="160"
                trigger="hover"
                :content="isDoctor ? scope.row.hcp_code : scope.row.hco_code"
              >
                <span
                  slot="reference"
                  :style="{ color: '#0069FA', cursor: 'pointer' }"
                  @click="gotoDetail(scope.row.id, scope.row.name)"
                >
                  {{ scope.row[column.property] }}
                </span>
              </el-popover>

              <span
                v-else
                :style="{ color: '#0069FA', cursor: 'pointer' }"
                @click="gotoDetail(scope.row.id, scope.row.name)"
              >
                {{ scope.row[column.property] }}
              </span>

              <!-- <i
              class="el-icon-document eyao-click-copy"
              :data-clipboard-text="scope.row[column.property]"
            ></i> -->
            </template>

            <!-- 所属机构名称跳转详情 -->
            <template v-else-if="column.property === 'hco_name'">
              <div
                @click="gotoHcoName(scope.row.hco_name, scope.row.hco_id)"
                :style="{ color: '#0069FA', cursor: 'pointer' }"
              >
                {{ scope.row[column.property] }}
              </div>
            </template>

            <!-- 涉及时间字段，format值 -->
            <template v-else-if="column.format">
              <template
                v-if="
                  column.property === 'establishment_date' || column.property === 'nhc_issue_date'
                "
              >
                {{ scope.row[column.property] | getYMD }}
              </template>
              <template v-else>{{ scope.row[column.property] | getFormatTime }}</template>
            </template>

            <template v-else>{{ scope.row[column.property] }}</template>
          </template>
        </el-table-column>

        <!-- 操作列 -->
        <el-table-column v-if="!isMid && role !== 3" min-width="130" fixed="right" label="操作">
          <div
            slot-scope="scope"
            v-if="getAuthorization('dataList-edit') || getAuthorization('dataList-delete')"
            class="data-list-option"
          >
            <div
              v-if="getAuthorization('dataList-edit')"
              @click="gotoEdit(scope.row.id, scope.row.name)"
            >
              编辑
            </div>
            <div v-if="getAuthorization('dataList-delete')" @click="deleteData(scope.row.id)">
              删除
            </div>
          </div>
        </el-table-column>

        <template v-slot:empty>
          <div class="no_data" v-if="!requestData && tableData.length === 0">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>
    </div>

    <!-- <template v-if="!requestData && tableData.length === 0">
      <div class="no_data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div>
    </template> -->

    <template v-if="requestData">
      <div class="listLoading">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </template>

    <!-- 批量编辑抽屉 -->
    <el-drawer
      title="批量编辑"
      class="datalist-editDrawer-warp"
      :visible.sync="editDrawerShow"
      direction="rtl"
      :size="480"
      :before-close="beforeCloseEditDrawer"
    >
      <div class="datalist-edit-drawer">
        <div class="datalist-edit-drawer-step">
          <el-steps :active="editDrawerActive" align-center>
            <el-step title="选择编辑字段"></el-step>
            <el-step title="确认编辑信息"></el-step>
          </el-steps>
        </div>
        <!-- 第一步确定要更新的字段与更新值 -->
        <div v-if="editDrawerActive === 1" class="datalist-edit-drawer-content">
          <div class="datalist-edit-drawer-content-title">
            <div class="datalist-edit-drawer-content-title-item">字段名称</div>
            <div class="datalist-edit-drawer-content-title-item">更新值</div>
          </div>
          <div
            v-for="(obj, index) in multipleEditArray"
            :key="index"
            class="datalist-edit-drawer-content-input"
          >
            <el-select
              class="datalist-edit-drawer-content-input-item"
              v-model="obj.property"
              clearable
              filterable
              @change="updateMultipleEdit(index)"
              placeholder="选择字段"
            >
              <el-option
                v-for="item in keyOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 更新值部分 -->
            <el-select
              v-if="obj.type === 'select'"
              class="datalist-edit-drawer-content-input-item"
              v-model="obj.value"
              clearable
              filterable
              placeholder="选择字段值"
            >
              <el-option
                v-for="item in valueOpts[obj.property]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-cascader
              v-else-if="obj.type === 'cascader'"
              class="datalist-edit-drawer-content-input-item"
              v-model="obj.value"
              ref="cascaderMallCatergory"
              :props="cascaderData[obj.property]"
              @change="hanldeCascaderVal"
            ></el-cascader>
            <el-input
              v-else
              class="datalist-edit-drawer-content-input-item"
              v-model="obj.value"
              placeholder="输入字段值"
            ></el-input>
            <i
              v-if="multipleEditArray.length > 1"
              class="el-icon-minus"
              @click="handleDelMultiple(index)"
            />
          </div>
          <div class="datalist-edit-drawer-content-add" @click="addMultipleEdit">
            <i class="el-icon-plus"></i>
            添加编辑字段
          </div>
        </div>

        <!-- 第二步确认要更新的值与目标数据 -->
        <div v-if="editDrawerActive === 2" class="datalist-edit-drawer-content edit-drawer-two">
          <div class="datalist-edit-drawer-content-title">
            编辑字段 {{ validMultipleEditItem }} 个
          </div>
          <el-table
            :data="multipleEditArray"
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F8', color: '#667082' }"
            :row-style="{ color: '#1c2028' }"
          >
            <el-table-column prop="title" label="字段名称"></el-table-column>
            <el-table-column prop="label" label="更新值"></el-table-column>
          </el-table>

          <div class="datalist-edit-drawer-content-title">
            编辑数据 {{ selectedData.length }} 条
          </div>
          <div class="datalist-edit-drawer-content-data">
            <el-table
              :data="selectedData"
              style="width: 100%"
              :header-cell-style="{ background: '#F2F3F8', color: '#667082' }"
              :row-style="{ color: '#1c2028' }"
            >
              <el-table-column prop="address" label="医院名称">
                <template slot-scope="scope">
                  {{ scope.row.name }}
                  <i
                    slot="reference"
                    v-if="selectedData.length > 1"
                    class="el-icon-delete"
                    @click="delHospitalName(scope)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 下一步、上一步、取消按钮 -->
        <div class="datalist-edit-drawer-footer">
          <template v-if="editDrawerActive === 1">
            <el-button
              class="datalist-edit-drawer-button edit-drawer-nextBtn"
              :disabled="validMultipleEditItem === 0"
              type="primary"
              @click="handleNextEdit"
            >
              下一步
            </el-button>
            <el-button
              class="datalist-edit-drawer-button edit-drawer-cancelBtn"
              @click="closeMulpEdit"
            >
              取消
            </el-button>
          </template>
          <template v-if="editDrawerActive === 2">
            <el-button
              slot="reference"
              v-auto-unfocus
              class="datalist-edit-drawer-button edit-drawer-saveBtn"
              type="primary"
              @click="multipleEdit"
            >
              保存
            </el-button>
            <el-button
              v-auto-unfocus
              class="datalist-edit-drawer-button edit-drawer-preBtn"
              @click="editDrawerActive = 1"
            >
              上一步
            </el-button>
          </template>
        </div>
      </div>
    </el-drawer>

    <!-- 删除弹窗 -->
    <el-dialog
      title="确认删除吗"
      :visible.sync="showDeleteDialog"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="deleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="showDeleteDialog = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
        <!-- <div class="cancelBtn" @click="showDeleteDialog = false">取 消</div>
        <div class="comfirmBtn" @click="deleteConfirm">
          确 定
        </div> -->
      </div>
    </el-dialog>

    <!-- 数据导出model -->
    <el-dialog
      title="数据导出"
      :visible.sync="showDataExportDialog"
      width="680px"
      top="20vh"
      class="dataExport-warp"
      :before-close="handleCloseDialog"
    >
      <el-form :model="dataExportForm">
        <el-form-item
          v-if="showExportOpt"
          label="导出数据类型"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          {{ title }}
        </el-form-item>

        <el-form-item
          v-if="showExportOpt"
          label="导出数据数量"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          <el-select
            v-model="dataExportForm.quantity"
            clearable
            filterable
            style="width: 90px"
            @change="chooseNum"
          >
            <el-option
              v-for="item in dataExportForm.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          &nbsp;条
        </el-form-item>

        <el-form-item
          label="选择导出字段"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          <div class="transfer-field-warp">
            <el-transfer
              ref="exportFieldTransfer"
              v-model="dataExportForm.exportRightFields"
              :data="dataExportForm.allExportFields"
              :titles="['可选字段', '导出字段']"
              target-order="push"
              @change="changeExportField"
              @right-check-change="exportCheckedFields"
            ></el-transfer>

            <div class="transfer-field-move">
              <el-button
                :disabled="dataExportForm.checkedFields.length === 0"
                @click="handleExportFieldUp"
              >
                <i class="el-icon-top"></i>
              </el-button>
              <el-button
                :disabled="dataExportForm.checkedFields.length === 0"
                @click="handleExportFieldDown"
              >
                <i class="el-icon-bottom"></i>
              </el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="handleCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="dataExportForm.exportRightFields.length === 0"
          @click="comfirmExportData"
        >
          导 出
        </el-button>
      </div>
    </el-dialog>

    <!-- 数据导入model -->
    <el-dialog
      title="数据导入"
      :visible.sync="showDataImportDialog"
      width="800px"
      top="20vh"
      class="dataImport-dialog"
      :before-close="closeDataImportDialog"
    >
      <div class="dataImport-warp">
        <div class="dataImport-warp-step">
          <el-steps :active="dataImportActive" align-center>
            <el-step title="上传导入文件"></el-step>
            <el-step title="导入数据预览"></el-step>
          </el-steps>
        </div>

        <!-- 第一步，上传 -->
        <div class="dataImport-first-step" v-if="dataImportActive === 1">
          <el-upload
            class="dataImport-upload"
            ref="dataImport-upload"
            drag
            :limit="1"
            action="#"
            :file-list="uploadFileList"
            :before-upload="beforeUpload"
            :on-change="hanldeUploadChange"
            :on-remove="hanldeDelUploadList"
            :http-request="uploadHttpRequest"
          >
            <template v-if="!hideUploadTips">
              <i class="el-icon-plus"></i>
              <div class="el-upload__text">点击或拖拽文件到此处上传</div>
            </template>
            <div class="el-upload__tip" :class="{ 'tip-mt-140': hideUploadTips }">
              后缀为.xlsx的excel文件；列名在文件第一行；数据不能跨行，不能包含空行
            </div>
          </el-upload>
          <!-- 进度条 -->
          <el-progress
            v-if="showProgress"
            :percentage="progressPercent"
            :color="colors"
          ></el-progress>

          <div class="dataImport-downTemp" @click="handleDownloadTemp">下载导入模板</div>
        </div>

        <!-- 第二步，预览 -->
        <div class="dataImport-second-step" v-if="dataImportActive === 2">
          <div class="dataImport-second-step-file-name">
            <i class="el-icon-document"></i>
            {{ uploadFileName }}
          </div>
          <div class="dataImport-second-step-file-info">
            <div class="file-info-left">
              <div class="file-info-type">
                数据类型:
                <span>{{ title }}</span>
              </div>
              <div class="file-info-num">
                导入数据量:
                <span>{{ previewUplaodNum }}</span>
              </div>
            </div>
            <div class="file-info-right">
              <span>预览仅展示前20条数据</span>
            </div>
          </div>
          <div class="dataImport-second-step-file-content">
            <el-table
              :data="previewUplaodTable"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F3F8',
                'box-shadow': 'inset 0px -1px 0px #E5EAF2',
                padding: 0,
              }"
              :header-row-style="{ height: '38px', color: '#667082', background: '#F2F3F8' }"
              :row-style="{ height: '60px' }"
              :cell-style="{ padding: '0' }"
            >
              <el-table-column
                v-for="column in previewUplaodConfig"
                :key="`previewUplaod-${column.property}`"
                :prop="column.property"
                :width="column.width"
                :label="column.title"
              >
                <template slot="header">
                  {{ column.title }}
                </template>
                <template slot-scope="scope">
                  <template v-if="column.property === '成立日期'">
                    {{ scope.row[column.property] | getYMD }}
                  </template>
                  <template v-else>
                    {{ scope.row[column.property] }}
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <template v-if="previewUplaodNum === 0">
            <div class="preview_no_data">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="未查询到相关数据"
              />
              未查询到相关数据
            </div>
          </template>
        </div>

        <!-- 下一步、上一步、取消按钮 -->
        <div class="dataImport-footer">
          <template v-if="dataImportActive === 1">
            <el-button
              class="dataImport-button dataImport-cancelBtn"
              @click="closeDataImportDialog"
            >
              取消
            </el-button>
            <el-button
              class="dataImport-button dataImport-nextBtn"
              :disabled="uploadNextStep"
              type="primary"
              @click="dataImportNext"
            >
              下一步
            </el-button>
          </template>
          <template v-if="dataImportActive === 2">
            <el-button
              class="dataImport-button dataImport-cancelBtn"
              @click="closeDataImportDialog"
            >
              取消
            </el-button>
            <el-button
              v-auto-unfocus
              class="dataImport-button dataImport-preBtn"
              @click="dataImportActive = 1"
            >
              上一步
            </el-button>
            <el-button
              slot="reference"
              v-auto-unfocus
              class="dataImport-button dataImport-comfirmBtn"
              type="primary"
              @click="comfirmImportData"
            >
              确定导入
            </el-button>
          </template>
        </div>
      </div>
    </el-dialog>

    <!-- 设置列表字段model -->
    <el-dialog
      title="设置列表字段"
      :visible.sync="showTransferField"
      width="600px"
      top="30vh"
      class="transfer-field-dialog"
      :before-close="closeTransferField"
    >
      <div class="transfer-field-warp">
        <el-transfer
          ref="fieldTransfer"
          v-model="transferedFields"
          :data="allDataFields"
          :titles="['可选字段', '显示字段']"
          target-order="push"
          @change="handleTransferChange"
          @right-check-change="choosedRightField"
        ></el-transfer>
        <div class="transfer-field-move">
          <el-button :disabled="rightFieldCheck.length === 0" @click="handleFieldUp">
            <i class="el-icon-top"></i>
          </el-button>
          <el-button :disabled="rightFieldCheck.length === 0" @click="handleFieldDown">
            <i class="el-icon-bottom"></i>
          </el-button>
        </div>
        <div class="transfer-field-reset" @click="handleResetTransfer">恢复默认字段</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="closeTransferField">取 消</el-button>
        <el-button
          type="primary"
          :disabled="transferedFields.length === 0"
          @click="comfirmTransfer"
        >
          应 用
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from '@/store';
import eyaoTable from '@/components/table';
import eyaoTableEditCell from '@/components/table-edit-cell';
import eyaoTableSearchHeader from '@/components/table-search-header';
import hospitalConfig from './config/hospital';
import dealerConfig from './config/dealer';
import shopConfig from './config/shop';
import instrumentConfig from './config/medical-machine';
import enterpriseConfig from './config/manufacturer';
import categoryConfig from './config/other';
import doctorConfig from './config/doctor';
import midOrgConfig from './config/mid-org';
import midDocConfig from './config/mid-doc';
import hospitalLabelConfig from './labelConfig/hospital';
import doctorLabelConfig from './labelConfig/doctor';
import midOrgLabelConfig from './labelConfig/mid-org';
import midDocLabelConfig from './labelConfig/mid-doc';
import ClipboardJS from 'clipboard';
import * as apis from '@/api/data';
import { REQUEST_TYPE, REQUEST_TITLE_TYPE, SPECIAL_CHAR, DICT_TYPE } from '@/utils/constant';
import { getAdminDistrict } from '@/api/cascader-api';
import { dateFormat, getAuthorization } from '@/utils/util';
import XLSX from 'xlsx';
import { getBlob, downloadFile } from '@/utils/downloadExcel';
import { hcoCustom, hcpCustom } from './customField';
import { CreateDcr } from '@/api/dcr';
import { GetObjURL } from '@/api/upload';
import { GetConfig } from '@/api/dcr-config';
import _ from 'lodash';

export default {
  name: 'DataList',
  components: {
    eyaoTable,
    eyaoTableEditCell,
    eyaoTableSearchHeader,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
    getYMD(value) {
      if (value && !value.startsWith('0')) {
        const date = new Date(value);
        let year = date.getFullYear();
        year = year < 10 ? `0${year}` : year;
        let month = date.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let day = date.getDate();
        day = day < 10 ? `0${day}` : day;
        return `${year}年${month}月${day}日`;
      }
      return '';
    },
  },
  data() {
    const self = this;
    return {
      isOpenDcr: false,
      dcrRole: 1,
      third: false,

      // 数据请求
      requestData: true,
      // 表格的配置
      config: [],
      assginConfig: [],
      // 表格下拉框的label值
      labelConfig: [],
      configObj: {},
      // 数据列表名称
      title: '',
      // 全表搜索框
      searchText: '',
      // 表头搜索框
      tableSearchText: '',
      // 现有表格筛选条件的存储对象 key为属性名 value为值
      filterObject: {},
      // 表头筛选条件显示的列表
      filterList: [],
      // 编辑抽屉显示
      editDrawerShow: false,
      // 编辑抽屉确认按钮二次确认popover显示
      editDrawerPopVisible: false,
      // 编辑抽屉步骤条
      editDrawerActive: 1,
      // 多选编辑时选择的需要编辑的字段 初始化给一个空槽
      multipleEditArray: [{ property: '', value: '', type: 'select', title: '', label: '' }],
      // 引入的示例数据
      tableData: [],
      keyOpts: [],
      valueOpts: {},
      // 数据总量
      dataCount: 0,
      // 已选择的数据
      selectedData: [],
      // 删除窗口是否显示 id 删除原因
      showDeleteDialog: false,
      deleteDialogTarget: '',
      deleteReason: '',
      cascaderData: {
        admin_division: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level, value } = node;
            const params = {
              level: `${level}`,
            };
            if (value) {
              params.code = value;
            }
            getAdminDistrict(params).then(res => {
              const nodes = res.district_list.map(item => ({
                value: item.code,
                label: item.name,
                leaf: !item.level,
              }));
              resolve(nodes);
            });
          },
        },
        standard_dept_name: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            const params = {
              code: level === 0 ? '' : node.value.split(',')[0],
              level: level.toString(),
            };
            if (level > 2) {
              return;
            }
            apis.GetStandardDeptList(params).then(res => {
              res.deps.forEach(item => {
                const obj = {
                  value: item.code,
                  label: item.name,
                  leaf: !item.level,
                };
                nodes.push(obj);
              });
              resolve(nodes);
            });
          },
        },
        type_name: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            apis
              .GetOrgTypeList({ level: 2 })
              .then(res => {
                const arr = res.datas.filter(
                  ele => ele.type_name === REQUEST_TITLE_TYPE[self.$route.query.type],
                );
                const typeId = arr[0].type_id;
                return typeId;
              })
              .then(typeId => {
                const params = {
                  parend_ids: level === 0 ? [typeId] : [node.value],
                  level: level + 3,
                };
                if (level > 2) {
                  return;
                }
                apis.GetOrgTypeList(params).then(res => {
                  res.datas.forEach(item => {
                    const obj = {
                      value: item.type_id,
                      label: item.type_name,
                      leaf: !item.is_leaf,
                    };
                    nodes.push(obj);
                  });
                  resolve(nodes);
                });
              });
          },
        },
      },
      // 展示数据导出模态框
      showDataExportDialog: false,
      dataExportForm: {
        quantity: '20',
        options: [
          {
            value: 'twenty',
            label: '20',
          },
          {
            value: 'fifty',
            label: '50',
          },
          {
            value: 'oneHundred',
            label: '100',
          },
          {
            value: 'twoHundred',
            label: '200',
          },
        ],
        allExportFields: [],
        exportRightFields: [],
        checkedFields: [],
      },
      administrationList: [], // 行政区划数据
      standardDeptData: [], // 标准科室数据
      typeNameData: [], // 类型细分数据
      showSearchInput: false, // 列表页标题下方的搜索框
      propertyName: [
        'type_name',
        'hospital_level',
        'ownership',
        'is_medical_insurance',
        'business_status',
        'in_doubt',
        'source',
        'gender',
        'hcp_type',
        'hcp_status',
        'standard_dept_name',
        'professional_title',
        'org_type',
        'hospital_profit_type',
        'administrative_title',
        'academic_title',
      ],
      showEditBtn: false, // 编辑按钮的禁用
      scrollElement: null,
      pageDataSize: 10,
      currentDataPage: 1,
      // 数据导入
      showDataImportDialog: false,
      // 数据导入步骤条
      dataImportActive: 1,
      // 导入文件进度
      uploadNextStep: true,
      // 上传文件后，tips隐藏
      hideUploadTips: false,
      uploadFileList: [],
      // 文件上传的进度条
      showProgress: false,
      progressPercent: 20,
      // 上传的cos地址
      uploadCosUrl: '',
      // 上传文件名
      uploadFileName: '',
      // 上传后预览table
      previewUplaodNum: 0,
      previewUplaodTable: [],
      previewUplaodConfig: [],
      // 设置transfer列表字段
      showTransferField: false,
      // 全部字段
      allDataFields: [],
      // 所有的已选字段
      transferedFields: [],
      // 已选字段中的勾选的数据
      rightFieldCheck: [],
      // 数据导出model的opt展示
      showExportOpt: true,

      colors: [
        { color: '#0069f9', percentage: 30 },
        { color: '#23b712', percentage: 100 },
      ],
    };
  },
  computed: {
    needLoading() {
      return !(this.tableData.length === this.dataCount);
    },
    // 批量编辑的字段数量
    validMultipleEditItem() {
      let count = 0;
      this.multipleEditArray.forEach(item => {
        if (item.property !== '') count += 1;
      });
      return count;
    },
    // 是否为中转库 影响页面上是否提供对数据的编辑删除等操作
    isMid() {
      return this.$route.query.type === 'mid-org' || this.$route.query.type === 'mid-doc';
    },
    isDoctor() {
      return this.$route.query.type === 'doctor';
    },
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'data-list') {
        this.init();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.leavePageOpt();
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.leavePageOpt();
    next();
  },
  mounted() {
    // 所有class=eyao-click-copy的元素都提供点击后复制功能
    new ClipboardJS('.eyao-click-copy');
    this.init();
  },
  created() {
    this.getConfig();
  },
  methods: {
    // 获取dcr配置
    getConfig() {
      GetConfig({})
        .then(res => {
          console.log(res);
          this.isOpenDcr = res.config.if_open_dcr;
          this.third = res.config.if_optv;
          this.dcrRole = res.config.dcr_role;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    init() {
      // 数据初始化
      this.tableData = [];
      this.requestData = true;
      // 根据type参数调整使用的表格配置
      if (this.$route.query.type === undefined) {
        this.$message.error('未提供数据列表类型');
        this.$router.push('home');
      } else {
        this.switchTab();
        // this.getFieldPermission();
        // this.GetOrgTypeLists();
        this.dealConfig();
        this.getOptions();
        this.filterInit();
      }
    },

    // 字段权限
    getFieldPermission() {
      if (this.isDoctor) {
        const param1 = {
          index_name: 't_hcp',
          operation: 'read',
        };
        apis.GetFieldPermissions(param1).then(res => {
          console.log(res);
        });
        const param2 = {
          index_name: 't_hcp',
          operation: 'read/write',
        };
        apis.GetFieldPermissions(param2).then(res => {
          console.log(res);
        });
      } else if (!this.isDoctor && !this.isMid) {
        const param1 = {
          index_name: 't_hco',
          operation: 'read',
        };
        apis.GetFieldPermissions(param1).then(res => {
          console.log(res);
        });
        const param2 = {
          index_name: 't_hco',
          operation: 'read/write',
        };
        apis.GetFieldPermissions(param2).then(res => {
          console.log(res);
        });
      }
    },

    switchTab() {
      switch (this.$route.query.type) {
        case 'hospital':
          this.title = '医院';
          store.commit('user/setBreadcrumb', [
            { name: '医院', path: '/dashboard/data-list?type=hospital' },
          ]);
          this.config = hospitalConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'hospital-ji':
          this.title = '基层医疗卫生机构';
          store.commit('user/setBreadcrumb', [
            { name: '基层医疗卫生机构', path: '/dashboard/data-list?type=hospital-ji' },
          ]);
          this.config = hospitalConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'hospital-pro':
          this.title = '专业公共卫生机构';
          store.commit('user/setBreadcrumb', [
            { name: '专业公共卫生机构', path: '/dashboard/data-list?type=hospital-pro' },
          ]);
          this.config = hospitalConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'hospital-other':
          this.title = '其他医疗卫生机构';
          store.commit('user/setBreadcrumb', [
            { name: '其他医疗卫生机构', path: '/dashboard/data-list?type=hospital-other' },
          ]);
          this.config = hospitalConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'dealer':
          this.title = '经销商';
          store.commit('user/setBreadcrumb', [
            { name: '经销商', path: '/dashboard/data-list?type=dealer' },
          ]);
          this.config = dealerConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'shop':
          this.title = '药店';
          store.commit('user/setBreadcrumb', [
            { name: '药店', path: '/dashboard/data-list?type=shop' },
          ]);
          this.config = shopConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'medical-machine':
          this.title = '医疗器械';
          store.commit('user/setBreadcrumb', [
            { name: '医疗器械', path: '/dashboard/data-list?type=medical-machine' },
          ]);
          this.config = instrumentConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'manufacturer':
          this.title = '生产企业';
          store.commit('user/setBreadcrumb', [
            { name: '生产企业', path: '/dashboard/data-list?type=manufacturer' },
          ]);
          this.config = enterpriseConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'other':
          this.title = '其他分类';
          store.commit('user/setBreadcrumb', [
            { name: '其他分类', path: '/dashboard/data-list?type=other' },
          ]);
          this.config = categoryConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'doctor':
          this.title = '医生';
          store.commit('user/setBreadcrumb', [
            { name: '医生', path: '/dashboard/data-list?type=doctor' },
          ]);
          this.config = doctorConfig;
          this.labelConfig = doctorLabelConfig;
          break;
        case 'mid-org':
          this.title = '机构';
          store.commit('user/setBreadcrumb', [
            { name: '机构', path: '/dashboard/data-list?type=mid-org' },
          ]);
          this.config = midOrgConfig;
          this.labelConfig = midOrgLabelConfig;
          break;
        case 'mid-doc':
          this.title = '医疗专业人士';
          store.commit('user/setBreadcrumb', [
            { name: '医疗专业人士', path: '/dashboard/data-list?type=mid-doc' },
          ]);
          this.config = midDocConfig;
          this.labelConfig = midDocLabelConfig;
          break;
        default:
          this.$message.error('无效的数据列表类型');
          this.$router.push('home');
          break;
      }
      if (this.role === 3) {
        const arr = [
          'wmqe_number',
          'in_doubt',
          'doubt_reason',
          'source',
          'comment',
          'del_remark',
          'qa_persons',
        ];
        // 商务人员对清洗备注、部门归一字段不可见
        this.config = this.config.filter(ele => !arr.includes(ele.property));
      }
      this.assginConfig = JSON.parse(JSON.stringify(this.config));
    },

    dealConfig() {
      const preferConig = localStorage.getItem(`${this.$route.query.type}-prefer-config`);
      this.config = preferConig ? JSON.parse(preferConig) : this.config;

      const transProperty = [];
      this.config.forEach(item => {
        this.configObj[item.property] = item;

        // 商务人员不能直接编辑,对系统字段不可见
        if (this.role === 3) item.edit = false;

        // 处理批量编辑的字段名称
        if (item.edit) {
          this.keyOpts.push({
            value: item.property,
            label: item.title,
            search: item.search,
          });
        }

        transProperty.push(item.property);
      });

      // 自定义设置列表字段的'显示字段'
      this.dealCutsomConfig(transProperty);
    },

    dealCutsomConfig(transProperty) {
      if (transProperty.length === 0) return;
      // 医院机构
      if (this.$route.query.type.startsWith('hospital')) {
        const delFields = [
          '审批机关',
          '诊疗科目',
          '英文名',
          '邮编',
          '主页',
          '电话1',
          '邮箱1',
          '微信公众号1',
          '注册号',
          '注册地址',
          '注册资金',
          '经营范围',
          '纳税人识别号',
          '特色科室',
          '病床数量',
          '门急诊数量',
          '科室数量',
          '执业医师数量',
          '未名企鹅编码',
        ];

        let hosiptalField = hcoCustom.filter(item => !delFields.includes(item.label));
        // 商务人员对清洗备注、部门归一字段不可见
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          hosiptalField = hosiptalField.filter(item => !BMQX.includes(item.key));
        }
        this.transferedFields = transProperty; // '显示'字段
        this.allDataFields = hosiptalField; // 可选字段
      }

      // 经销商等
      const export2 = ['dealer', 'manufacturer', 'medical-machine', 'other'];
      if (export2.includes(this.$route.query.type)) {
        const delFields = [
          '医院级别',
          '所有制性质',
          '是否医保',
          '医院营利性质',
          '卫健委名称',
          '审批机关',
          '诊疗科目',
          '法人',
          '企业负责人',
          '英文名',
          '邮编',
          '主页',
          '电话1',
          '邮箱1',
          '微信公众号1',
          '注册号',
          '注册地址',
          '注册资金',
          '组织机构代码',
          '经营范围',
          '纳税人识别号',
          '特色科室',
          '病床数量',
          '门急诊数量',
          '科室数量',
          '执业医师数量',
          '未名企鹅编码',
        ];

        let dealerField = hcoCustom.filter(item => !delFields.includes(item.label));
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          dealerField = dealerField.filter(item => !BMQX.includes(item.key));
        }
        this.transferedFields = transProperty;
        this.allDataFields = dealerField;
      }

      // 药店
      if (this.$route.query.type === 'shop') {
        const delFields = [
          '医院级别',
          '所有制性质',
          '医院营利性质',
          '卫健委名称',
          '审批机关',
          '诊疗科目',
          '法人',
          '企业负责人',
          '英文名',
          '邮编',
          '主页',
          '电话1',
          '邮箱1',
          '微信公众号1',
          '注册号',
          '注册地址',
          '注册资金',
          '组织机构代码',
          '经营范围',
          '纳税人识别号',
          '特色科室',
          '病床数量',
          '门急诊数量',
          '科室数量',
          '执业医师数量',
          '未名企鹅编码',
        ];

        let shopField = hcoCustom.filter(item => !delFields.includes(item.label));
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          shopField = shopField.filter(item => !BMQX.includes(item.key));
        }
        this.transferedFields = transProperty;
        this.allDataFields = shopField;
      }

      // 医生
      if (this.isDoctor) {
        const delFields = ['颁发机构', '证书颁发日期', '未名企鹅编码'];

        let doctorField = hcpCustom.filter(item => !delFields.includes(item.label));
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          doctorField = doctorField.filter(item => !BMQX.includes(item.key));
        }
        this.transferedFields = transProperty;
        this.allDataFields = doctorField;
      }
      // console.log(this.allDataFields, 'this.allDataFields');
      // console.log(this.transferedFields, 'this.transferedFields');
    },

    handleSelectionChange(val) {
      val.forEach(ele => {
        const idx = this.tableData.findIndex(item => item.id === ele.id);
        ele.tableIndex = idx;
      });
      console.log(val);
      this.selectedData = val;
      if (val.length > 0) {
        this.showEditBtn = true;
      } else {
        this.showEditBtn = false;
      }
    },

    // 离开页面的option
    leavePageOpt() {
      localStorage.removeItem('data-filter');
      this.keyOpts = [];
      this.selectedData = [];
      this.$refs.table.clearSelection();
      this.showEditBtn = false;
      // 滚动条重置初始值
      [this.scrollElement] = this.$refs.table.$el.getElementsByClassName('el-table__body-wrapper');
      this.scrollElement.scrollLeft = 0;
      this.multipleEditArray = [{ property: '', value: '', type: 'select', title: '', label: '' }];

      this.currentDataPage = 1;
      this.pageDataSize = 10;
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage()[this.$route.query.type] || {};
      this.updateFilterList();
      this.searchData();
    },
    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      this.searchData();
    },
    updateFilterList() {
      const list = [];
      const { filterObject } = this;
      Object.keys(filterObject).forEach(key => {
        if (JSON.stringify(filterObject[key]) !== '{}') {
          if (key === 'admin_division') {
            list.push({ name: key, label: filterObject.admin_division.labelList });
          } else if (key === 'type_name') {
            list.push({ name: key, label: filterObject.type_name.labelList });
          } else if (key === 'standard_dept_name') {
            list.push({ name: key, label: filterObject.standard_dept_name.labelList });
          } else if (filterObject[key].label) {
            list.push({ name: key, label: filterObject[key].label });
          }
        }
      });
      this.filterList = list;
      console.log(this.filterList, '筛选条件list');
      this.setStorage();
      // this.searchData();
      /* 这里在重置筛选条件的时候（调用deleteAllFilter），会出现重复调用searchData的情况，这里可能会造次重置后数据闪屏并且重置失败的现象
        原因是因为forEach循环调用了5次，请求5次接口
        解决方法，不在updateFilterList()里面调用searchData()，统统提到外面 
      */
    },

    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.searchData();
    },

    deleteAllFilter() {
      localStorage.removeItem('data-filter');
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      setTimeout(() => {
        this.searchData();
      });
    },

    edit(id, property, val, labelList) {
      console.log(id, property, val, labelList);
      let dataAlterContents = [];
      if (property === 'admin_division') {
        // 行政区划
        dataAlterContents = this.dealAdminContent(id, val, labelList);
      } else if (property === 'standard_dept_name') {
        // 标准科室
        dataAlterContents = this.dealStandardContent(id, val, labelList);
      } else if (property === 'type_name') {
        // 类型细分
        dataAlterContents = this.dealTypeNameContent(id, val, labelList);
      } else {
        const beforeEdit = this.tableData.filter(ele => ele.id === id)[0];
        console.log(beforeEdit, '修改之前的值');
        if (beforeEdit && beforeEdit[property] === labelList) {
          this.$message.error('数据未发生改变');
          return;
        }

        dataAlterContents = [
          {
            field_name: property,
            before_val: '',
            after_val: Array.isArray(val) ? val.join(',') : val,
          },
        ];
      }
      console.log(dataAlterContents, 'dataAlterContents');

      if (dataAlterContents.length === 0) {
        return;
      }
      this.updateData(dataAlterContents, id).then(() => {
        if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
          this.$message.success('编辑成功');
          this.searchData();
        } else {
          this.$message.success('编辑请求提交成功');
          this.searchData();
        }
      });
    },

    dealAdminContent(id, val, labelList) {
      const area = ['province_name', 'city_name', 'district_name', 'town_name', 'village_name'];
      const areaCode = ['province_code', 'city_code', 'district_code', 'town_code', 'village_code'];
      const areaArr = [];
      const codeArr = [];
      const filterData = this.administrationList.filter(ele => ele.id === id)[0];
      console.log(filterData, 'filterData');
      val.forEach((item, index) => {
        areaArr.push({
          field_name: area[index],
          before_val: filterData[`${area[index]}`],
          after_val: labelList[index],
        });
        codeArr.push({
          field_name: areaCode[index],
          before_val: filterData[`${areaCode[index]}`],
          after_val: item,
        });
      });
      const areaArrRemain = this.getRemainArr(area, areaArr, filterData);
      const codeArrRemain = this.getRemainArr(areaCode, codeArr, filterData);

      console.log(areaArrRemain, 'areaArr_noData');
      console.log(codeArrRemain, 'codeArrNoData');
      return [...areaArr, ...areaArrRemain, ...codeArr, ...codeArrRemain];
    },

    dealStandardContent(id, val, labelList) {
      const standardName = ['standard_dept_name1', 'standard_dept_name2', 'standard_dept_name3'];
      const standardCode = ['standard_dept1', 'standard_dept2', 'standard_dept3'];
      const names = [];
      const ids = [];
      const filterData = this.standardDeptData.filter(ele => ele.id === id)[0];
      console.log(filterData, 'filterData');

      // 判断原来的值和修改之后的值是否一样，如果一样则不触发修改
      if (
        val.length === 1 &&
        val[0] === filterData.standard_dept1 &&
        !filterData.standard_dept2 &&
        !filterData.standard_dept3
      ) {
        return [];
      }
      if (
        val.length === 2 &&
        val[0] === filterData.standard_dept1 &&
        val[1] === filterData.standard_dept2 &&
        !filterData.standard_dept3
      ) {
        return [];
      }
      if (
        val.length === 3 &&
        val[0] === filterData.standard_dept1 &&
        val[1] === filterData.standard_dept2 &&
        val[2] === filterData.standard_dept3
      ) {
        return [];
      }

      val.forEach((item, index) => {
        names.push({
          field_name: standardName[index],
          before_val: filterData[standardName[index]],
          after_val: labelList[index],
        });
        ids.push({
          field_name: standardCode[index],
          before_val: filterData[standardCode[index]],
          after_val: item,
        });
      });

      let lastNameVal = filterData.standard_dept_name3;
      if (!lastNameVal) {
        lastNameVal = filterData.standard_dept_name2
          ? filterData.standard_dept_name2
          : filterData.standard_dept_name1;
      }
      let lastIdVal = filterData.standard_dept3;
      if (!lastIdVal) {
        lastIdVal = filterData.standard_dept2
          ? filterData.standard_dept2
          : filterData.standard_dept1;
      }
      const fixedObj1 = {
        field_name: 'standard_dept_name',
        before_val: lastNameVal,
        after_val: labelList[labelList.length - 1],
      };
      const fixedObj2 = {
        field_name: 'standard_dept_id',
        before_val: lastIdVal,
        after_val: val[val.length - 1],
      };

      const nameArrRemain = this.getRemainArr(standardName, names, filterData);
      const codeArrRemain = this.getRemainArr(standardCode, ids, filterData);

      console.log(nameArrRemain, codeArrRemain);
      return [...names, ...nameArrRemain, ...ids, ...codeArrRemain, fixedObj1, fixedObj2];
    },

    dealTypeNameContent(id, val, labelList) {
      const filterData = this.typeNameData.filter(ele => ele.id === id)[0];
      console.log(filterData, 'filterData');
      // 判断原来的值和修改之后的值是否一样，如果一样则不触发修改
      // if (
      //   val.length === 1 &&
      //   val[0] === filterData.hco_type3 &&
      //   !filterData.hco_type4 &&
      //   !filterData.hco_type5
      // ) {
      //   return [];
      // }
      // if (
      //   val.length === 2 &&
      //   val[0] === filterData.hco_type3 &&
      //   val[1] === filterData.hco_type4 &&
      //   !filterData.hco_type5
      // ) {
      //   return [];
      // }
      // if (
      //   val.length === 3 &&
      //   val[0] === filterData.hco_type3 &&
      //   val[1] === filterData.hco_type4 &&
      //   val[2] === filterData.hco_type5
      // ) {
      //   return [];
      // }

      // 最新做法：旧的type_name值和新值相同则不改
      if (labelList[labelList.length - 1] === filterData.type_name) return [];

      const typeArr = ['hco_type3', 'hco_type4', 'hco_type5'];
      const typeNameArr = ['hco_type_name3', 'hco_type_name4', 'hco_type_name5'];
      const arr1 = [];
      const arr2 = [];
      val.forEach((ele, index) => {
        arr1.push({
          field_name: typeArr[index],
          before_val: filterData[typeArr[index]],
          after_val: ele,
        });
        arr2.push({
          field_name: typeNameArr[index],
          before_val: filterData[typeNameArr[index]],
          after_val: labelList[index],
        });
      });
      const fixedObj = {
        field_name: 'type_name',
        before_val: filterData.type_name || '',
        after_val: labelList[labelList.length - 1],
      };

      const typeArrRemain = this.getRemainArr(typeArr, arr1, filterData);
      const typeNameArrRemain = this.getRemainArr(typeNameArr, arr2, filterData);

      console.log(typeArrRemain, typeNameArrRemain);
      return [...arr1, ...typeArrRemain, ...arr2, ...typeNameArrRemain, fixedObj];
    },

    // 找到2个数组不同的元素
    getRemainArr(arr, obj, filterData = {}) {
      const result = [];
      if (arr.length === 0 || obj.length === 0) return result;

      const arrField = obj.map(item => item.field_name);
      const remainArr = arr.filter(item => !arrField.includes(item));
      for (const ele of remainArr) {
        result.push({
          field_name: ele,
          before_val: filterData.id ? filterData[ele] : '',
          after_val: '',
        });
      }
      return result;
    },

    search(property, val, formType, label) {
      console.log(property, val, formType, label);

      let reqType = 2;
      const propertyArr = ['professional_title', 'hcp_type', 'type_name'];

      if (formType === 'cascader' || formType === 'select') {
        if (propertyArr.includes(property)) {
          reqType = 1;
        }
        if (property === 'admin_division') {
          const area = ['province_name', 'city_name', 'district_name', 'town_name', 'village_name'];
          const labelList = label.join('/');
          const adminDivision = {};
          const areaObj = {};
          val.forEach((ele, index) => {
            areaObj[area[index]] = { label: label[index], value: label[index], formType, type: 1 };
          });
          console.log(areaObj);
          adminDivision.admin_division = areaObj;
          adminDivision.admin_division.labelList = labelList;
          this.filterChange(adminDivision);
        } else if (property === 'standard_dept_name') {
          const standardName = [
            'standard_dept_name1',
            'standard_dept_name2',
            'standard_dept_name3',
          ];
          const labelList = label.join('/');
          const standardDept = {};
          const obj = {};
          val.forEach((ele, index) => {
            obj[standardName[index]] = {
              label: label[index],
              value: label[index],
              formType,
              type: 1,
            };
          });
          standardDept.standard_dept_name = obj;
          standardDept.standard_dept_name.labelList = labelList;
          console.log(standardDept);
          this.filterChange(standardDept);
        } else if (property === 'type_name') {
          const typeNameArr = ['hco_type_name3', 'hco_type_name4', 'hco_type_name5'];
          const labelList = label.join('/');
          const typeNames = {};
          const obj = {};
          val.forEach((ele, index) => {
            obj[typeNameArr[index]] = {
              label: label[index],
              value: label[index],
              formType,
              type: 1,
            };
          });
          typeNames.type_name = obj;
          typeNames.type_name.labelList = labelList;
          console.log(typeNames);
          this.filterChange(typeNames);
        } else {
          // 是否医保、是否存疑、性别,type为9
          const speciaType = ['is_medical_insurance', 'in_doubt', 'gender'];
          if (speciaType.includes(property)) reqType = 9;
          this.filterChange({ [property]: { label, value: val, formType, type: reqType } });
        }
      } else if (formType === 'input' || formType === 'autocomplete') {
        // 版本、type为9
        const speciaType = ['record_version'];
        let type = 1;
        if (speciaType.includes(property)) type = 9;
        this.filterChange({ [property]: { label: val, value: val, type } });
      }
    },

    hanldeCascaderVal() {
      const label = this.$refs.cascaderMallCatergory[0].getCheckedNodes()[0].pathLabels;
      console.log(label);
      console.log(this.multipleEditArray);
      this.multipleEditArray.forEach(ele => {
        if (ele.property === 'standard_dept_name' && label.length > 0) {
          ele.label = label.join(',');
        } else if (ele.property === 'type_name' && label.length > 0) {
          ele.label = label.join(',');
        }
      });
    },
    // 新增批量编辑中的字段
    addMultipleEdit() {
      // 字段选项为空时不再新增编辑字段
      this.keyOpts.length &&
        this.multipleEditArray.push({
          property: '',
          value: '',
          type: 'select',
          title: '',
          label: '',
        });
    },
    // 当需要编辑的字段变化时 需要根据不同字段决定使用下拉还是文本输入作为输入方式
    updateMultipleEdit(index) {
      for (let i = 0; i < this.multipleEditArray.length; i++) {
        this.multipleEditArray[i].type = this.configObj[this.multipleEditArray[i].property]?.search;
        this.multipleEditArray[i].title = this.configObj[this.multipleEditArray[i].property]?.title;

        // 重新更新字段名称时，删掉之前的更新值
        if (index === i) {
          this.multipleEditArray[i].value = '';
        }
      }
      console.log(this.multipleEditArray);
    },

    // 批量编辑-删除编辑字段
    handleDelMultiple(idx) {
      this.multipleEditArray.splice(idx, 1);
    },

    // 批量编辑点击'下一步'
    handleNextEdit() {
      // 为避免重复，现在给予用户提示
      const arr = this.multipleEditArray.map(ele => ele.property);
      const setArr = [...new Set(arr)];
      if (arr.length === setArr.length) {
        this.editDrawerActive = 2;
        this.multipleEditArray.forEach(ele => {
          if (ele.type === 'select') {
            ele.label = this.getCodeLabel(ele.property, ele.value);
          }
          if (ele.type === 'input') {
            ele.label = ele.value;
          }
          if (ele.type === 'cascader') {
            if (ele.property === 'type_name' || ele.property === 'standard_dept_name') {
              ele.label = ele.label.replace(/,/g, '/');
            }
          }
        });
        console.log(this.multipleEditArray);
      } else {
        this.$message.error('存在同一种字段类型');
      }
    },

    // 批量编辑，第二步删除'编辑数据'
    delHospitalName(scope) {
      const index = scope.$index;
      const { tableIndex } = scope.row;
      this.selectedData.splice(index, 1);
      this.$refs.table.$refs.table.toggleRowSelection(this.tableData[tableIndex]);
    },

    // 批量编辑确认
    multipleEdit() {
      console.log(this.multipleEditArray, this.selectedData, 'multipleEditArray');
      let dataAlterContents = [];
      this.multipleEditArray.forEach(item => {
        const value = Array.isArray(item.value) ? item.value.join(',') : item.value;
        console.log(value);

        if (item.property === 'type_name') {
          const typeArr = ['hco_type3', 'hco_type4', 'hco_type5'];
          const typeNameArr = ['hco_type_name3', 'hco_type_name4', 'hco_type_name5'];
          const arr1 = [];
          const arr2 = [];
          const fixlabel = item.label.split('/');
          value.split(',').forEach((ele, index) => {
            arr1.push({
              field_name: typeArr[index],
              before_val: '',
              after_val: ele,
            });
            arr2.push({
              field_name: typeNameArr[index],
              before_val: '',
              after_val: fixlabel[index],
            });
          });
          const fixedObj = {
            field_name: 'type_name',
            before_val: '',
            after_val: fixlabel[fixlabel.length - 1],
          };

          const typeArrRemain = this.getRemainArr(typeArr, arr1);
          const typeNameArrRemain = this.getRemainArr(typeNameArr, arr2);
          // console.log(typeArrRemain, typeNameArrRemain);

          dataAlterContents = [...arr1, ...typeArrRemain, ...arr2, ...typeNameArrRemain, fixedObj];
        } else if (item.property === 'standard_dept_name') {
          const deptArr = ['standard_dept1', 'standard_dept2', 'standard_dept3'];
          const deptNameArr = ['standard_dept_name1', 'standard_dept_name2', 'standard_dept_name3'];
          const arr1 = [];
          const arr2 = [];
          value.split(',').forEach((ele, index) => {
            arr1.push({
              field_name: deptArr[index],
              before_val: '',
              after_val: ele,
            });
            arr2.push({
              field_name: deptNameArr[index],
              before_val: '',
              after_val: item.label.split('/')[index],
            });
          });

          const fixlabel = item.label.split('/');
          const fixCode = value.split(',');
          const fixedObj1 = {
            field_name: 'standard_dept_name',
            before_val: '',
            after_val: fixlabel[fixlabel.length - 1],
          };
          const fixedObj2 = {
            field_name: 'standard_dept_id',
            before_val: '',
            after_val: fixCode[fixCode.length - 1],
          };

          const deptArrRemain = this.getRemainArr(deptArr, arr1);
          const deptNameArrRemain = this.getRemainArr(deptNameArr, arr2);
          dataAlterContents = [
            ...arr1,
            ...deptArrRemain,
            ...arr2,
            ...deptNameArrRemain,
            fixedObj1,
            fixedObj2,
          ];
        } else {
          dataAlterContents.push({
            field_name: item.property,
            before_val: '',
            after_val: value,
          });
        }
      });
      console.log(dataAlterContents);
      const editList = [];
      this.selectedData.forEach(item => {
        editList.push(this.updateData(dataAlterContents, item.id));
      });
      Promise.all(editList).then(res => {
        console.log('批量编辑', res);
        const flag = res.every(item => item.id);
        if (flag) {
          if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
            this.$message.success('编辑成功');
            this.searchData();
          } else {
            this.$message.success('编辑请求提交成功');
            this.searchData();
          }
        }
      });
      this.$refs.table.clearSelection();
      this.editDrawerPopVisible = false;
      this.editDrawerShow = false;
      this.editDrawerActive = 1;
      this.multipleEditArray = [{ property: '', value: '', type: 'select', title: '', label: '' }];
    },

    // 更新编辑
    updateData(content, id, delReason = '') {
      // const params = {
      //   obj_name: REQUEST_TYPE[this.$route.query.type],
      //   alter_time: Date.parse(new Date()),
      //   data_id: id,
      //   content,
      //   data_confirm: 0, // 确认保存标志 0-未确认，需要打回疑似数据 1-已确认，对疑似数据进行保存
      // };
      // console.log(params, 'sss');
      // return apis.EditMainDataDetail(params);

      if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
        const params = {
          obj_name: REQUEST_TYPE[this.$route.query.type],
          alter_time: Date.parse(new Date()),
          data_id: id,
          content,
          data_confirm: 0, // 确认保存标志 0-未确认，需要打回疑似数据 1-已确认，对疑似数据进行保存
        };
        console.log(params, 'sss');
        return apis.EditMainDataDetail(params);
      }
      const item = this.tableData.find(item => item.id === id);
      let deptName = '';
      if (this.isDoctor) {
        deptName = item.standard_dept_name3
          ? item.standard_dept_name3
          : item.standard_dept_name2
          ? item.standard_dept_name2
          : item.standard_dept_name1;
      }
      const params = {
        dcr_type: delReason ? 3 : 2, // DCR类型 1-新建, 2-修改, 3-删除(失效)
        dcr_data_type: this.isDoctor ? 1 : 2, // DCR数据类型, 1-hcp, 2-hco
        data_code: this.isDoctor ? item.hcp_code : item.hco_code, // 涉及hco/hcp的编码
        dcr_source_id: '', // 上游id
        source_dcr: '', // 租户ID与关联的DCR编码
        name: item.name, // DCR涉及hcp/hco名称
        hcp_hco_name: item.hco_name || '', // 涉及hcp所属机构名称
        hcp_city_name: item.province_name || '', // 涉及hcp城市名称
        hcp_dept_name: deptName, // 涉及hcp所属科室(最细粒度)
        data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.type], // 涉及hco/hcp详细类型
        content,
        delete_reason: delReason, // 删除原因(dcr_type=3的时候传入)
      };
      console.log(params);
      return CreateDcr(params);

      // const item = this.tableData.find(item => item.id === id);
      // let deptName = '';
      // if (this.isDoctor) {
      //   deptName = item.standard_dept_name3
      //     ? item.standard_dept_name3
      //     : item.standard_dept_name2
      //     ? item.standard_dept_name2
      //     : item.standard_dept_name1;
      // }
      // const params = {
      //   dcr_type: delReason ? 3 : 2, // DCR类型 1-新建, 2-修改, 3-删除(失效)
      //   dcr_data_type: this.isDoctor ? 1 : 2, // DCR数据类型, 1-hcp, 2-hco
      //   data_code: this.isDoctor ? item.hcp_code : item.hco_code, // 涉及hco/hcp的编码
      //   dcr_source_id: '', // 上游id
      //   source_dcr: '', // 租户ID与关联的DCR编码
      //   name: item.name, // DCR涉及hcp/hco名称
      //   hcp_hco_name: item.hco_name || '', // 涉及hcp所属机构名称
      //   hcp_city_name: item.province_name || '', // 涉及hcp城市名称
      //   hcp_dept_name: deptName, // 涉及hcp所属科室(最细粒度)
      //   data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.type], // 涉及hco/hcp详细类型
      //   content,
      //   delete_reason: delReason, // 删除原因(dcr_type=3的时候传入)
      // };
      // console.log(params);
      // return CreateDcr(params);
    },

    // 关闭批量编辑抽屉框
    closeMulpEdit() {
      this.editDrawerShow = false;
      this.editDrawerActive = 1;
    },

    beforeCloseEditDrawer() {
      this.closeMulpEdit();
    },

    gotoDetail(id, name) {
      // this.$router.push({
      //   name: 'data-detail',
      //   query: { id, type: 'preview', source: this.$route.query.type, name },
      // });
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { id, type: 'preview', source: this.$route.query.type, name },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },

    // 所属机构名称跳转详情
    gotoHcoName(hcoName, hcoId) {
      const req = {
        obj_name: 't_hco',
        data_id: hcoId,
        fields: ['hco_type'],
      };
      apis
        .GetMainDataDetail(req)
        .then(res => {
          if (res?.data_detail) {
            const hcoType = JSON.parse(res.data_detail).hco_type;
            let source = '';
            Object.keys(REQUEST_TITLE_TYPE).forEach(ele => {
              if (REQUEST_TITLE_TYPE[ele] === hcoType) {
                source = ele;
              }
            });
            const routerData = this.$router.resolve({
              path: '/dashboard/data-detail',
              query: {
                id: hcoId,
                name: hcoName,
                type: 'preview',
                source,
              },
            });
            // console.log(decodeURIComponent(routerData.href));
            window.open(decodeURIComponent(routerData.href), '_blank');
          }
        })
        .catch(() => {
          this.$message.error('查不到数据，该数据可能已经被删除');
        });
    },

    gotoEdit(id, name) {
      // this.$router.push({
      //   name: 'data-detail',
      //   query: { id, type: 'edit', source: this.$route.query.type, name },
      // });
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { id, type: 'edit', source: this.$route.query.type, name },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    gotoCreate() {
      // this.$router.push({
      //   name: 'data-detail',
      //   query: { type: 'new', source: this.$route.query.type },
      // });
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { type: 'new', source: this.$route.query.type },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    deleteData(id) {
      this.deleteDialogTarget = id;
      this.showDeleteDialog = true;
    },
    deleteConfirm() {
      console.log('delete', this.deleteDialogTarget, this.deleteReason);
      if (this.deleteReason.length === 0) {
        this.$message.error('请填写删除原因');
      } else {
        // const params = {
        //   obj_name: REQUEST_TYPE[this.$route.query.type],
        //   data_id: this.deleteDialogTarget,
        //   reason: this.deleteReason,
        // };
        // apis.DeleteMainDataDetail(params).then(res => {
        //   console.log(res, '-------');
        //   this.$message.success('删除成功');
        //   this.showDeleteDialog = false;
        //   this.deleteDialogTarget = '';
        //   this.deleteReason = '';
        //   setTimeout(() => {
        //     this.searchData();
        //   }, 500);
        // });
        if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
          const params = {
            obj_name: REQUEST_TYPE[this.$route.query.type],
            data_id: this.deleteDialogTarget,
            reason: this.deleteReason,
          };
          apis.DeleteMainDataDetail(params).then(res => {
            console.log(res, '-------');
            this.$message.success('删除成功');
            this.showDeleteDialog = false;
            this.deleteDialogTarget = '';
            this.deleteReason = '';
            setTimeout(() => {
              this.searchData();
            }, 500);
          });
        } else {
          this.updateData([], this.deleteDialogTarget, this.deleteReason).then(() => {
            this.$message.success('删除请求提交成功');
            this.showDeleteDialog = false;
            this.deleteDialogTarget = '';
            this.deleteReason = '';
            this.searchData();
          });
        }
        // this.updateData([], this.deleteDialogTarget, this.deleteReason).then(res => {
        //   if (res?.id) {
        //     this.$message.success('删除请求提交成功');
        //     this.showDeleteDialog = false;
        //     this.deleteDialogTarget = '';
        //     this.deleteReason = '';
        //     this.searchData();
        //   }
        // });
      }
    },
    // 禁止id列的宽度被修改 固定其宽度为300
    fixColWidth(newW, oldW, col) {
      if (col.columnKey === 'id') {
        col.width = 300;
      }
    },
    // 关键字搜索
    searchKeyword() {
      // 搜索前进行校验
      if (this.searchText.length === 0) {
        this.$message.error('搜索内容不能为空');
      } else if (this.searchText.length < 4) {
        this.$message.error('至少输入4个中文字符长度');
      } else if (this.searchText.length > 10) {
        this.$message.error('最多只能输入10个字符');
      } else if (SPECIAL_CHAR.test(this.searchText)) {
        this.$message.error('不能输入特殊字符');
      } else {
        this.filterChange({ keyword: [this.searchText] });
      }
    },

    getSearchOption() {
      console.log(this.filterObject, 'search filterObject');
      const optKeys = {};
      const specialKey = ['admin_division', 'type_name', 'standard_dept_name'];
      Object.keys(this.filterObject).forEach(ele => {
        if (JSON.stringify(this.filterObject[ele]) !== '{}') {
          if (!specialKey.includes(ele) && ele !== 'keyword') {
            optKeys[ele] = this.filterObject[ele];
          } else {
            const obj = this.filterObject[ele];
            Object.keys(obj).forEach(e => {
              if (e !== 'labelList') {
                optKeys[e] = obj[e];
              }
            });
          }
        }
      });
      const searchOption = [];
      if (JSON.stringify(optKeys) !== '{}') {
        Object.keys(optKeys).forEach(key => {
          searchOption.push({
            field_name: key, // 字段名称
            data_name: REQUEST_TYPE[this.$route.query.type], // 表名
            type: Number(optKeys[key].type), // 筛选类型； 1-包含，2-等于
            key: optKeys[key].value,
          });
        });
      }
      console.log(searchOption, 'searchOption');
      return searchOption;
    },

    searchData() {
      this.requestData = true;
      const searchOption = this.getSearchOption();
      const params = {
        main_data: REQUEST_TYPE[this.$route.query.type],
        table_name: REQUEST_TYPE[this.$route.query.type],
        data: REQUEST_TITLE_TYPE[this.$route.query.type],
        keyword: '',
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        search_fields: this.dealDivisionParam(),
        SearchOptions: searchOption,
      };
      const api = this.isMid ? apis.GetStagingDataListByType : apis.SearchMainDataByType;
      api(params)
        .then(res => {
          if (res && res.data) {
            const dataList = res.data.map(item => JSON.parse(item));
            this.dataCount = res.total_num;

            if (!this.isMid && !this.isDoctor) {
              // 处理行政区划
              this.dealAdminData(dataList);
              // 处理二级分类、类型细分
              this.dealTypeNameData(dataList);
            }
            if (this.isDoctor) {
              // 处理标准科室
              this.dealStandardData(dataList);
            }

            this.dealDataLabel(dataList);
            this.tableData = dataList;
            console.log(this.tableData, 'tableData');
            setTimeout(() => {
              this.requestData = false;
            }, 500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$message.error(`${err.message}`);
          this.requestData = false;
        });
    },

    dealAdminData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        arr.push({
          id: ele.id,
          province_name: ele.province_name,
          city_name: ele.city_name,
          district_name: ele.district_name,
          town_name: ele.town_name,
          village_name: ele.village_name,
          province_code: ele.province_code,
          city_code: ele.city_code,
          district_code: ele.district_code,
          town_code: ele.town_code,
          village_code: ele.village_code,
        });
        const adminDivision = `${ele.province_name}/${ele.city_name}/${ele.district_name}/${ele.town_name}/${ele.village_name}`;
        ele.admin_division = this.delEndStr(adminDivision);
      });
      this.administrationList = arr;
    },

    dealStandardData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
        const standardName1 = ele.standard_dept_name1 ? ele.standard_dept_name1 : '';
        const standardName2 = ele.standard_dept_name2 ? ele.standard_dept_name2 : '';
        const standardName3 = ele.standard_dept_name3 ? ele.standard_dept_name3 : '';
        arr.push({
          id: ele.id,
          standard_dept1: ele.standard_dept1,
          standard_dept2: ele.standard_dept2,
          standard_dept3: ele.standard_dept3,
          standard_dept_name1: standardName1,
          standard_dept_name2: standardName2,
          standard_dept_name3: standardName3,
        });
        const standardName = `${standardName1}/${standardName2}/${standardName3}`;
        ele.standard_dept_name = this.delEndStr(standardName);
      });
      this.standardDeptData = arr;
    },

    dealTypeNameData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        arr.push({
          id: ele.id,
          hco_type1: ele.hco_type1,
          hco_type_name1: ele.hco_type_name1,
          hco_type2: ele.hco_type2,
          hco_type_name2: ele.hco_type_name2,
          hco_type3: ele.hco_type3,
          hco_type_name3: ele.hco_type_name3,
          hco_type4: ele.hco_type4,
          hco_type_name4: ele.hco_type_name4,
          hco_type5: ele.hco_type5,
          hco_type_name5: ele.hco_type_name5,
          type_name: ele.type_name,
        });
        // const typeName = `${ele.hco_type_name3}/${ele.hco_type_name4}/${ele.hco_type_name5}`;
        // ele.type_name = this.delEndStr(typeName);
        ele.hco_type = ele.hco_type_name2;
      });
      this.typeNameData = arr;
    },

    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },

    // 处理请求字段
    dealDivisionParam() {
      let allProperty = this.assginConfig.map(item => item.property);

      if (this.isDoctor) {
        // 标准科室
        const arr = [
          'standard_dept1',
          'standard_dept2',
          'standard_dept3',
          'standard_dept_name1',
          'standard_dept_name2',
          'standard_dept_name3',
        ];
        // ‘自定义字段’要展示的字段
        const arr1 = [
          'nhc_multi_sites',
          'nhc_license_number',
          'record_version',
          'comment',
          'doubt_reason',
        ];

        allProperty = [...allProperty, ...arr, 'id', 'hcp_code', 'hco_id', ...arr1];
      }

      if (!this.isMid && !this.isDoctor) {
        // 行政区划
        const arr = [
          'province_name',
          'city_name',
          'district_name',
          'town_name',
          'village_name',
          'province_code',
          'city_code',
          'district_code',
          'town_code',
          'village_code',
        ];
        // 二级分类、类型细分
        const hcoTypeArr = [
          'hco_type1',
          'hco_type_name1',
          'hco_type2',
          'hco_type_name2',
          'hco_type3',
          'hco_type_name3',
          'hco_type4',
          'hco_type_name4',
          'hco_type5',
          'hco_type_name5',
        ];
        // ‘自定义字段’要展示的字段
        const arr1 = [
          'parent_hco',
          'hospital_profit_type',
          'legal_person',
          'organization_code',
          'operation_mode',
          'business_type',
          'company_type',
          'record_version',
          'comment',
          'doubt_reason',
          'charge_persons',
        ];

        allProperty = [...allProperty, ...hcoTypeArr, ...arr, 'id', 'hco_code', ...arr1];
      }

      // 中转区
      if (this.isMid) {
        allProperty = [...allProperty, 'id'];
      }

      return allProperty;
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const propertyArr = ['standard_dept_name', 'type_name'];
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if ((this.isMid && key === 'professional_title') || propertyArr.includes(key)) {
            return;
          }
          if (this.propertyName.includes(key)) {
            // console.log(key, ele[key]);
            // 向下拉取数据，会导致之前转化为label的数据消失，是因为转了文字后，这里不能识别。
            // 解决方法：判断是否是数字或者字母开头，纯文字不管（已经转为label）
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.labelConfig.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    // pagination
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.searchData();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.searchData();
    },

    getOptions() {
      const optionsApi = [];
      const opts = {};
      // console.log(this.config, 'options---');
      this.config.forEach(item => {
        if (item.search === 'select') {
          let params = {
            dict_api_name: item.property,
            dict_type: DICT_TYPE[this.$route.query.type],
          };
          if (item.property === 'source') {
            params = {
              dict_api_name: 'source',
              dict_type: '4',
            };
          }
          if (item.property === 'org_type' && this.$route.query.type === 'mid-org') return;
          if (item.property === 'gender' && this.$route.query.type === 'mid-doc') return;

          if (item.property === 'type_name') return;
          optionsApi.push(
            apis.GetDictOptionList(params).then(res => {
              opts[item.property] = res.dict_list.map(opt => {
                return {
                  text: opt.label,
                  label: opt.label,
                  value: opt.code,
                };
              });
              return true;
            }),
          );
        }
      });
      Promise.all(optionsApi).then(() => {
        Object.keys(opts).forEach(key => {
          this.$set(this.valueOpts, key, opts[key]);
        });
      });
    },

    // 获取机构类型名数据
    GetOrgTypeLists() {
      const arr = [
        { queryName: 'hospital', name: '医院', code: '201' },
        { queryName: 'hospital-ji', name: '基层医疗卫生机构', code: '202' },
        { queryName: 'hospital-pro', name: '专业公共卫生机构', code: '203' },
        { queryName: 'hospital-other', name: '其他医疗卫生机构', code: '204' },
        { queryName: 'dealer', name: '经销商', code: '101' },
        { queryName: 'shop', name: '药店', code: '102' },
      ];
      let type2 = '201';
      arr.forEach(ele => {
        if (ele.queryName === this.$route.query.type) {
          type2 = ele.code;
        }
      });
      const params = [
        { field: 'level', values: ['5'] },
        { field: 'type2', values: [type2] },
        { field: 'is_deleted', values: ['2'] },
      ];
      apis
        .GetOrgTypeList({ filters: params })
        .then(res => {
          if (res && res.datas) {
            const data = JSON.parse(res.datas);
            let list = data.map(item => {
              return { label: item.name, value: item.type5 };
            });
            const obj = {};
            // 防止出现相同数据
            list = list.reduce((item, next) => {
              obj[next.value] ? '' : (obj[next.value] = true && item.push(next));
              return item;
            }, []);
            this.$set(this.valueOpts, 'type_name', list);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    setStorage() {
      const filterData = this.getStorage();
      filterData[this.$route.query.type] = this.filterObject;
      localStorage.setItem('data-filter', JSON.stringify(filterData));
    },
    getStorage() {
      const str = localStorage.getItem('data-filter');
      return str ? JSON.parse(str) : {};
    },
    apiData(queryString, property) {
      // 接口通过获取自动补全列表， 后期可以修改为通过关键词获取模糊查询列表
      console.log('queryString', queryString);
      const params = {
        dict_api_name: property,
        dict_type: DICT_TYPE[this.$route.query.type],
      };
      const createFilter = queryString => {
        return restaurant => {
          return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
      };
      return apis.GetDictOptionList(params).then(res => {
        console.log(res, 'GetDictOptionList');
        if (res) {
          const restaurants = res.map(item => ({
            value: item.label,
          }));
          const results = queryString ? restaurants.filter(createFilter(queryString)) : restaurants;
          return results;
        }
      });
    },
    getLabel(property) {
      console.log(property, 'property');
      if (property === 'keyword') return '关键字';
      if (property === 'admin_division') return '行政区划';
      return this.configObj[property].title;
    },

    // 数据导出
    handleExport() {
      if (this.selectedData.length > 200) {
        this.$message.error('一次导出数据不能超过200条!');
      } else if (this.selectedData.length === 0) {
        this.showExportOpt = true;
        this.customExport();
      } else {
        this.showExportOpt = false;
        this.customExport();
      }
    },

    // 选择导出数据的数量
    chooseNum(val) {
      const result = this.dataExportForm.options.find(item => item.value === val).label;
      console.log(result);
      this.dataExportForm.quantity = result;
    },

    // 自定义导出字段
    customExport() {
      this.showDataExportDialog = true;
      this.dataExportForm.allExportFields = this.dataExportFields();
    },

    // 数据导出全部字段
    dataExportFields() {
      if (this.$route.query.type.startsWith('hospital')) {
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          return hcoCustom.filter(item => !BMQX.includes(item.key));
        }
        return hcoCustom;
      }

      const export2 = ['dealer', 'manufacturer', 'medical-machine', 'other'];
      if (export2.includes(this.$route.query.type)) {
        const delFields = [
          '医院级别',
          '所有制性质',
          '是否医保',
          '医院营利性质',
          '卫健委名称',
          '审批机关',
          '诊疗科目',
          '法人',
          '企业负责人',
          '特色科室',
          '病床数量',
          '门急诊数量',
          '科室数量',
          '执业医师数量',
        ];
        let dealerField = hcoCustom.filter(item => !delFields.includes(item.label));
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          dealerField = dealerField.filter(item => !BMQX.includes(item.key));
        }
        return dealerField;
      }

      if (this.$route.query.type === 'shop') {
        const delFields = [
          '医院级别',
          '所有制性质',
          '医院营利性质',
          '卫健委名称',
          '审批机关',
          '诊疗科目',
          '法人',
          '企业负责人',
          '特色科室',
          '病床数量',
          '门急诊数量',
          '科室数量',
          '执业医师数量',
        ];
        let shopField = hcoCustom.filter(item => !delFields.includes(item.label));
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          shopField = shopField.filter(item => !BMQX.includes(item.key));
        }

        return shopField;
      }

      if (this.isDoctor) {
        if (this.role === 3) {
          const BMQX = [
            'wmqe_number',
            'in_doubt',
            'doubt_reason',
            'source',
            'comment',
            'del_remark',
            'qa_persons',
          ];
          return hcpCustom.filter(item => !BMQX.includes(item.key));
        }
        return hcpCustom;
      }
    },

    // 确认导出数据
    comfirmExportData() {
      const quantity = Number(this.dataExportForm.quantity); // 导出数量, int
      const searchOption = this.getSearchOption();
      const condition = {
        main_data: REQUEST_TYPE[this.$route.query.type],
        data: REQUEST_TITLE_TYPE[this.$route.query.type],
        keyword: this.filterObject.keyword ? this.filterObject.keyword[0] : '',
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        search_fields: this.comfirmExportFields(),
        SearchOptions: searchOption,
      };

      let exportIds = [];
      if (!this.showExportOpt) {
        exportIds = this.selectedData.map(item => item.id);
        // console.log(exportIds);
      }

      const params = {
        condition,
        offset: 0, // 偏移量
        limit: quantity,
        ids: exportIds, // 勾选数据的id
      };

      apis.CreateDataExportTask(params).then(res => {
        console.log('导出的数据', res);
        if (res && res.task_id) {
          this.$message.success('导出任务已生成，请到导入导出任务查看进度');
          this.handleCloseDialog();
          // 重置勾选
          this.selectedData = [];
          this.$refs.table.clearSelection();
        }
      });
    },

    comfirmExportFields() {
      // console.log(this.dataExportForm.exportRightFields);
      let exportFieldArr = [];
      // 行政区划
      const adminArr = [
        'province_name',
        'city_name',
        'district_name',
        'town_name',
        'village_name',
        'province_code',
        'city_code',
        'district_code',
        'town_code',
        'village_code',
      ];
      // 二级分类、类型细分
      const hcoTypeArr = [
        'hco_type1',
        'hco_type_name1',
        'hco_type2',
        'hco_type_name2',
        'hco_type3',
        'hco_type_name3',
        'hco_type4',
        'hco_type_name4',
        'hco_type5',
        'hco_type_name5',
      ];
      // 标准科室
      const standardArr = [
        'standard_dept1',
        'standard_dept2',
        'standard_dept3',
        'standard_dept_name1',
        'standard_dept_name2',
        'standard_dept_name3',
      ];
      this.dataExportForm.exportRightFields.forEach(ele => {
        if (ele === 'admin_division') {
          exportFieldArr.push(...adminArr);
        }
        if (ele === 'type_name') {
          exportFieldArr.push(...hcoTypeArr);
        }
        if (ele === 'standard_dept_name') {
          exportFieldArr.push(...standardArr);
        }
        exportFieldArr.push(ele);
      });
      const arr = ['admin_division', 'type_name', 'standard_dept_name'];
      exportFieldArr = exportFieldArr.filter(item => !arr.includes(item));
      console.log(exportFieldArr, '要导出的字段');

      return exportFieldArr;
    },

    // 关闭导出数据model
    handleCloseDialog() {
      this.dataExportForm.quantity = '20';
      this.showDataExportDialog = false;
      this.dataExportForm.exportRightFields = [];
      this.dataExportForm.checkedFields = [];
      // 重置滚动条
      this.$nextTick(() => {
        this.$refs.exportFieldTransfer.$children[0].$children[1].$el.scrollTop = 0;
        this.$refs.exportFieldTransfer.$children[0].checked = [];
      });
    },

    // 数据导入
    hanldeImport() {
      this.showDataImportDialog = true;
    },

    // 下载导入模板
    handleDownloadTemp() {
      console.log('下载导入模板');
      const dataType = this.isDoctor ? 't_hcp' : 't_hco';
      apis.GetDataImportTemplate({ data_type: dataType }).then(respoese => {
        console.log(respoese);
        if (respoese?.file_name) {
          GetObjURL({ url: respoese.url }).then(res => {
            console.log('转化后', res);
            getBlob(res.data.url, function(blob) {
              downloadFile(blob, respoese.file_name);
            });
          });
        }
      });
    },

    // 上传文件之前的检测
    beforeUpload(file) {
      // console.log(file, 'beforeUpload');
      const _this = this;
      return new Promise(function(resolve, reject) {
        const fileType = file.name.substr(file.name.indexOf('.') + 1);
        const xls = ['xls', 'xlsx'];
        if (xls.includes(fileType)) {
          resolve('校验成功!');
        } else {
          _this.$message.error('请上传后缀为.xlsx的excel文件');
          _this.hideUploadTips = false;
          reject(false);
        }
      });
    },

    // 改变上传文件
    hanldeUploadChange(file, fileList) {
      // console.log(file, fileList);
      if (fileList.length > 0) {
        this.uploadFileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
      }
      // console.log(this.uploadFileList);
    },

    uploadHttpRequest(param) {
      console.log(param);
      if (param.file?.name) {
        this.hideUploadTips = true;
        this.showProgress = true;
      }
      const _this = this;
      this.putCosObject({ fileObject: param.file }).then(res => {
        console.log(res, 'putCosObject');
        if (res?.data) {
          const { percent } = JSON.parse(res.progress);
          if (percent === 1) {
            // const formData = new FormData();
            // formData.append('file', param.file);
            // console.log(formData);
            // _this.readExcel(param.file);

            _this.uploadCosUrl = res.data;
            _this.uploadFileName = param.file.name;

            setTimeout(() => {
              _this.progressPercent = 100;
              _this.uploadNextStep = false;
            }, 500);
          }
        }
      });
    },

    // 解析Excel
    readExcel(file) {
      const reader = new FileReader();
      const rABS =
        typeof FileReader !== 'undefined' && (FileReader.prototype || {}).readAsBinaryString;
      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
      reader.onload = function(e) {
        let data = e.target.result;
        if (!rABS) {
          data = new Uint8Array(data);
        }
        const workBook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
        workBook.SheetNames.forEach(name => {
          const sheet = workBook.Sheets[name];
          const result = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            header: 1,
          });
          console.log(result, '解析后的excel');
        });
      };
    },

    // 删除上传的文件
    hanldeDelUploadList(file, fileList) {
      // console.log(file);
      this.uploadFileList = [];
      if (file?.name) {
        this.hideUploadTips = false;
        this.showProgress = false;
        this.progressPercent = 20;
      }
      if (fileList.length < 1) {
        this.uploadNextStep = true;
      }
    },

    // 数据导入下一步
    dataImportNext() {
      this.dataImportActive = 2;
      const hcoField = [
        '机构编码',
        '机构名称',
        '1级机构类型',
        '2级机构类型',
        '3级机构类型',
        '4级机构类型',
        '5级机构类型',
        '上级机构名称',
        '机构简介',
        '医院级别',
        '所有制性质',
        '是否医保',
        '医院营利性质',
        '卫健委名称',
        '审批机关',
        '诊疗科目',
        '法人',
        '企业负责人',
        '曾用名',
        '英文名',
        '别名',
        '省',
        '市',
        '区',
        '镇',
        '村',
        '地址',
        '经度',
        '纬度',
        '邮编',
        '主页',
        '电话1',
        '电话2',
        '电话3',
        '邮箱1',
        '邮箱2',
        '邮箱3',
        '微信公众号1',
        '微信公众号2',
        '微信公众号3',
        '注册号',
        '注册地址',
        '注册资金',
        '组织机构代码',
        '经营方式',
        '经营范围',
        '所属行业',
        '经营状态',
        '公司类型',
        '统一社会信用代码',
        '纳税人识别号',
        '成立日期',
        '特色科室',
        '病床数量',
        '门急诊数量',
        '科室数量',
        '执业医师数量',
        '操作类型',
      ];
      const hcpField = [
        '医生编码',
        '医生姓名',
        '姓名拼音',
        '性别',
        '所属机构名称',
        'HCP类型',
        'HCP状态',
        '挂牌科室',
        '1级标准科室',
        '2级标准科室',
        '3级标准科室',
        '专业职称',
        '执业许可证编码',
        '颁发机构',
        '医师级别',
        '执业类别',
        '执业地点',
        '执业范围',
        '多点执业数量',
        '操作类型',
      ];
      const previewConfig = [];
      if (this.isDoctor) {
        hcpField.forEach(ele => {
          previewConfig.push({
            property: ele,
            title: ele,
            width: 200,
          });
        });
      } else {
        hcoField.forEach(ele => {
          previewConfig.push({
            property: ele,
            title: ele,
            width: 200,
          });
        });
      }
      this.previewUplaodConfig = previewConfig;

      const params = {
        data_type: this.isDoctor ? 2 : 1, // 1-机构、2-医疗专业人士
        ope_database: this.isMid ? 2 : 1, // 操作数据库：1-数据中心、2-数据中转区
        url: this.uploadCosUrl, // 导入文件的cos url
        fields: this.isDoctor ? hcpField : hcoField,
      };
      console.log(params);
      apis.GetDataImportPreviewData(params).then(res => {
        console.log(res);
        if (res?.data.length > 0) {
          this.previewUplaodNum = res.total;
          const dataList = res.data.map(item => JSON.parse(item));
          this.previewUplaodTable = dataList;
          console.log(this.previewUplaodTable, '上传预览table');
        }
      });
    },

    // 确认导入数据
    comfirmImportData: _.throttle(
      function() {
        {
          const params = {
            data_type: this.isDoctor ? 2 : 1, // 1-机构、2-医疗专业人士
            ope_database: this.isMid ? 2 : 1, // 操作数据库：1-数据中心、2-数据中转区
            url: this.uploadCosUrl, // 导入文件的cos url
            file_name: this.uploadFileName, // 导入文件的文件名
          };
          apis.CreateDataImportTask(params).then(res => {
            if (res?.task_id) {
              this.closeDataImportDialog();
              this.$message.success('导入任务已生成');
            }
          });
        }
      },
      10000,
      {
        leading: true,
        trailing: false,
      },
    ),

    // 关闭数据导入model
    closeDataImportDialog() {
      this.showDataImportDialog = false;
      this.dataImportActive = 1;
      this.hideUploadTips = false;
      this.showProgress = false;
      this.progressPercent = 20;
      this.uploadFileList = [];
      this.previewUplaodNum = 0;
      this.previewUplaodTable = [];
      this.uploadNextStep = true;
    },

    // 打开设置列表字段穿梭框
    handleSetField() {
      this.showTransferField = true;
      this.resetTransEle();
    },

    // 关闭设置列表字段transfer
    closeTransferField() {
      this.showTransferField = false;
      this.resetTransEle();
    },

    // 应用自定义列表字段
    comfirmTransfer() {
      // console.log(this.transferedFields);
      const a = []; // 存放在原来的config有的property
      const b = []; // 存放在原来的config没有的property
      const aField = [];
      this.assginConfig.forEach(ele => {
        if (this.transferedFields.includes(ele.property)) {
          aField.push(ele);
          a.push(ele.property);
        }
      });
      this.transferedFields.forEach(ele => {
        if (!a.includes(ele)) {
          b.push(ele);
        }
      });
      // console.log(a, b, aField);

      const specialField = [
        {
          title: '机构编码',
          width: 300,
          property: 'hco_code',
          search: 'input',
        },
        {
          title: '上级机构名称',
          width: 300,
          property: 'parent_hco',
          search: 'input',
          edit: true,
        },
        {
          title: '医院营利性质',
          width: 300,
          property: 'hospital_profit_type',
          search: 'select',
          edit: true,
        },
        {
          title: '法人',
          width: 300,
          property: 'legal_person',
          search: 'input',
          edit: true,
        },
        {
          title: '企业负责人',
          width: 300,
          property: 'charge_persons',
          search: 'input',
          edit: true,
        },
        {
          title: '组织机构代码',
          width: 300,
          property: 'organization_code',
          search: 'input',
        },
        {
          title: '经营方式',
          width: 300,
          property: 'operation_mode',
          search: 'input',
          edit: true,
        },
        {
          title: '所属行业',
          width: 300,
          property: 'business_type',
          search: 'input',
          edit: true,
        },
        {
          title: '公司类型',
          width: 300,
          property: 'company_type',
          search: 'input',
          edit: true,
        },
        {
          title: '存疑原因',
          width: 300,
          property: 'doubt_reason',
          search: 'input',
        },
        {
          title: '备注评论',
          width: 300,
          property: 'comment',
          search: 'input',
          edit: true,
        },
        {
          title: '版本',
          width: 300,
          property: 'record_version',
          search: 'input',
        },
        // 医生
        {
          title: '人员编码',
          width: 300,
          property: 'hcp_code',
          search: 'input',
        },
        {
          title: '多点执业数量',
          width: 300,
          property: 'nhc_multi_sites',
          search: 'input',
        },
        {
          title: '执业许可证编码',
          width: 300,
          property: 'nhc_license_number',
          search: 'input',
        },
      ];
      const bField = specialField.filter(item => b.includes(item.property));
      const newConfig = [...aField, ...bField];
      this.dealNewConfig(newConfig);
      this.closeTransferField();
      this.searchData();
    },

    // 处理新的config
    dealNewConfig(newConfig) {
      // 将自定义后的字段数组按照’已选字段‘的顺序排序
      newConfig.sort((x, y) => {
        return (
          this.transferedFields.indexOf(x.property) - this.transferedFields.indexOf(y.property)
        );
      });
      console.log(newConfig, '自定义后的字段列表');

      // 以防重新渲染后视图更新不及时的问题
      this.config = [];
      this.keyOpts = [];
      this.configObj = {};
      this.$nextTick(() => {
        this.config = newConfig;
        this.config.forEach(item => {
          this.configObj[item.property] = item;

          if (this.role === 3) item.edit = false;

          if (item.edit) {
            this.keyOpts.push({
              value: item.property,
              label: item.title,
              search: item.search,
            });
          }
        });
        localStorage.setItem(`${this.$route.query.type}-prefer-config`, JSON.stringify(newConfig));
      });

      // ‘医院营利性质’要单独请求标准值
      if (this.transferedFields.includes('hospital_profit_type')) {
        apis
          .GetDictOptionList({ dict_api_name: 'hospital_profit_type', dict_type: '0' })
          .then(res => {
            if (res?.dict_list) {
              this.$nextTick(() => {
                this.$set(this.valueOpts, 'hospital_profit_type', res.dict_list);
              });
            }
          });
      }
    },

    handleTransferChange(value, direction) {
      // console.log(value, direction);
      // 左移操作，上下移动不可点
      if (direction === 'left') {
        this.rightFieldCheck = [];
      }
      // 右移操作，显示字段数组按照全部字段的顺序排序
      if (direction === 'right') {
        let arr = hcoCustom;
        if (this.isDoctor) arr = hcpCustom;
        const arr1 = arr.map(ele => ele.key);
        // console.log(arr1);
        this.transferedFields.sort((x, y) => {
          return arr1.indexOf(x) - arr1.indexOf(y);
        });
      }
    },

    // 右边字段勾选
    choosedRightField(val) {
      this.rightFieldCheck = val;
      // console.log(this.rightFieldCheck);
    },

    // 已选字段上移
    handleFieldUp() {
      if (this.rightFieldCheck.length === 1) {
        const idx = this.transferedFields.findIndex(item => item === this.rightFieldCheck[0]);
        // console.log(idx);
        if (idx === 0) {
          this.$message.warning('没有上移空间了');
          return;
        }
        // 取出上移字段
        const changeItem = JSON.parse(JSON.stringify(this.transferedFields[idx - 1]));
        // 删除字段
        this.transferedFields.splice(idx - 1, 1);
        // 插入字段
        this.transferedFields.splice(idx, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },

    // 已选字段下移
    handleFieldDown() {
      if (this.rightFieldCheck.length === 1) {
        const idx = this.transferedFields.findIndex(item => item === this.rightFieldCheck[0]);
        // console.log(idx);
        if (idx === this.transferedFields.length - 1) {
          this.$message.warning('没有下移空间了');
          return;
        }
        // 取出下移字段
        const changeItem = JSON.parse(JSON.stringify(this.transferedFields[idx]));
        // 删除字段
        this.transferedFields.splice(idx, 1);
        // 插入字段
        this.transferedFields.splice(idx + 1, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },

    // 恢复默认字段
    handleResetTransfer() {
      let transProperty = [];
      const export2 = ['dealer', 'manufacturer', 'medical-machine', 'other'];

      if (this.$route.query.type.startsWith('hospital')) {
        transProperty = hospitalConfig.map(item => item.property);
      } else if (export2.includes(this.$route.query.type)) {
        transProperty = dealerConfig.map(item => item.property);
      } else if (this.$route.query.type === 'shop') {
        transProperty = shopConfig.map(item => item.property);
      } else if (this.isDoctor) {
        transProperty = doctorConfig.map(item => item.property);
      }

      // 商务人员对清洗备注、部门归一字段不可见
      if (this.role === 3) {
        const BMQX = [
          'wmqe_number',
          'in_doubt',
          'doubt_reason',
          'source',
          'comment',
          'del_remark',
          'qa_persons',
        ];
        transProperty = transProperty.filter(item => !BMQX.includes(item));
      }

      this.dealCutsomConfig(transProperty);
      this.resetTransEle();
    },

    // 重置
    resetTransEle() {
      this.rightFieldCheck = [];
      this.$nextTick(() => {
        this.$refs.fieldTransfer.$children[0].$children[1].$el.scrollTop = 0;
        this.$refs.fieldTransfer.$children[3].$children[1].$el.scrollTop = 0;
        this.$refs.fieldTransfer.$children[0].checked = [];
        this.$refs.fieldTransfer.$children[3].checked = [];
      });
    },

    // 自定义导出字段
    changeExportField(value, direction) {
      if (direction === 'left') {
        this.dataExportForm.checkedFields = [];
      }
    },

    exportCheckedFields(val) {
      this.dataExportForm.checkedFields = val;
    },

    // 导出字段上移
    handleExportFieldUp() {
      if (this.dataExportForm.checkedFields.length === 1) {
        const idx = this.dataExportForm.exportRightFields.findIndex(
          item => item === this.dataExportForm.checkedFields[0],
        );
        // console.log(idx);
        if (idx === 0) {
          this.$message.warning('没有上移空间了');
          return;
        }
        // 取出上移字段
        const changeItem = JSON.parse(
          JSON.stringify(this.dataExportForm.exportRightFields[idx - 1]),
        );
        // 删除字段
        this.dataExportForm.exportRightFields.splice(idx - 1, 1);
        // 插入字段
        this.dataExportForm.exportRightFields.splice(idx, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },

    // 导出字段下移
    handleExportFieldDown() {
      if (this.dataExportForm.checkedFields.length === 1) {
        const idx = this.dataExportForm.exportRightFields.findIndex(
          item => item === this.dataExportForm.checkedFields[0],
        );
        // console.log(idx);
        if (idx === this.dataExportForm.exportRightFields.length - 1) {
          this.$message.warning('没有下移空间了');
          return;
        }
        // 取出下移字段
        const changeItem = JSON.parse(JSON.stringify(this.dataExportForm.exportRightFields[idx]));
        // 删除字段
        this.dataExportForm.exportRightFields.splice(idx, 1);
        // 插入字段
        this.dataExportForm.exportRightFields.splice(idx + 1, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },

    // 用户操作权限-功能权限
    getAuthorization,
  },
};
</script>
<style lang="scss" scoped>
@import './data-list.scss';
</style>
