export default [
  // {
  //   title: '性别',
  //   property: 'gender',
  //   value: [
  //     { code: '1', label: '女' },
  //     { code: '2', label: '男' },
  //     { code: '0', label: '未知' },
  //   ],
  // },
  {
    title: '数据来源',
    property: 'source',
    value: [
      { code: '0', label: '默认' },
      { code: '1', label: '企查查' },
      { code: '2', label: '天眼查' },
      { code: '3', label: '高德地图' },
      { code: '4', label: '腾讯地图' },
      { code: '5', label: '百度地图' },
      { code: '6', label: '医院' },
      { code: '7', label: '卫健委' },
    ],
  },
];
