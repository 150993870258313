export default [
  // {
  //   title: '系统ID',
  //   width: 300,
  //   property: 'id',
  //   search: 'input',
  //   sort: false,
  //   edit: false,
  // },
  {
    title: '姓名',
    'min-width': 100,
    property: 'name',
    search: 'input',
    sort: false,
    edit: false,
    fixed: true,
  },
  {
    title: '性别',
    width: 80,
    property: 'gender',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '所属机构名',
    'min-width': 300,
    property: 'org_name',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '所在科室',
    width: 150,
    property: 'dept_name',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '专业职称',
    width: 120,
    property: 'professional_title',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '学术职称',
    width: 120,
    property: 'academic_title',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '行政职务',
    width: 120,
    property: 'administrative_title',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '学历',
    width: 120,
    property: 'education_degree',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '卫健委执业证书编码',
    width: 180,
    property: 'nhc_license_number',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '卫健委医师级别',
    width: 150,
    property: 'nhc_professional_level',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '卫健委执业类别',
    width: 150,
    property: 'nhc_professional_type',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '卫健委执业地点',
    width: 150,
    property: 'nhc_location',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '省份',
    width: 120,
    property: 'province',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '城市',
    width: 120,
    property: 'city',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '数据来源',
    width: 150,
    property: 'source',
    search: 'select',
    sort: false,
    edit: false,
  },
  {
    title: '创建人',
    width: 120,
    property: 'create_user',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '创建时间',
    width: 180,
    property: 'create_time',
    search: '',
    sort: true,
    edit: false,
    format: true,
  },
  {
    title: '更新人',
    width: 120,
    property: 'update_user',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '更新时间',
    width: 180,
    property: 'update_time',
    search: '',
    sort: true,
    edit: false,
    format: true,
  },
];
